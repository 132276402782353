.common-action-item-list-control {
  display: flex;
}

.common-action-item-list-control > div:first-child {
  margin-right: 20px;
}

.common-action-item-list-control > div:last-child {
  flex: 1 0 0px;
}

.common-action-item-list {
  display: flex;
  flex-wrap: wrap;
}

.common-action-item-list > span {
  margin-right: 5px;
  margin-bottom: 5px;
}

body.loader-modal-open {
  overflow-y: hidden;
}

body.loader-modal-open > *:not(.loader-modal) {
  -webkit-filter: blur(5px);
}

body.loader-modal-open .loader-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: rgba(255, 255, 255, 0.5);
}

.loader-modal > div {
  background-color: white;
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 40px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
}

.loader-modal > div > span {
  display: block;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top-color: #999;
  animation: spin .5s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.editor img:not([width]):not([height]) {
  max-width: 100%;
}

.editor figure.image {
  display: table;
  margin: 10px;
}

.editor figure.image figure, .editor figure.image img {
  display: block;
}

.editor figure.image figcaption {
  font-style: italic;
  display: table-caption;
  caption-side: bottom;
  padding: 5px;
  font-size: .8em;
  text-align: center;
}

.dropdown-colorselector .btn-colorselector {
  border: 1px solid #ddd;
  background-color: none;
  height: 30px;
  width: 50px;
}

.save-modal .modal-content .modal-header {
  display: none;
}

.save-modal .modal-content .modal-footer {
  display: none;
}

.save-modal .modal-content div.modal-body > div {
  display: flex;
  justify-content: center;
}

.save-modal .modal-content div.modal-body > div.ok {
  display: none;
}

.save-modal .modal-content div.modal-body > div > div {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.save-modal .modal-content div.modal-body > div > div > * {
  flex: 0 0 auto;
}

.save-modal .modal-content div.modal-body > div > div h3 {
  margin-bottom: 0;
}

.save-modal .modal-content div.modal-body h3 {
  margin-top: 0;
}

.save-modal .modal-content.saved div.modal-body .loader {
  display: none;
}

.save-modal .modal-content.saved div.modal-body .ok {
  display: flex;
}

.save-modal .modal-content.saved .modal-footer {
  display: block;
}

body {
  padding-top: 70px;
}

div.title-row h1 {
  margin-top: 0 !important;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

div.title-row h1 small {
  text-transform: capitalize;
}

p.nhlpa-xl-text {
  font-size: 16px;
  font-style: italic;
}

.nhlpa-subdued-text {
  color: #8d8d8d;
}

table.clickable-rows tbody tr {
  cursor: pointer;
}
