﻿.common-action-item-list-control {
    display: flex;

    & > div {

        &:first-child {
            margin-right: 20px;
        }

        &:last-child {
            flex: 1 0 0px;
        }
    }
}

.common-action-item-list {
    display: flex;
    flex-wrap: wrap;

    & > span {
        margin-right: 5px;
        margin-bottom: 5px;
    }
}
