﻿body.loader-modal-open{

    overflow-y:hidden;

    & > *:not(.loader-modal) {
        -webkit-filter: blur(5px);
    }

    & .loader-modal {
        display:flex;
        align-items: center;
        justify-content: center;
    }
}

.loader-modal{
    display:none;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:100000;
    background-color: rgba(255,255,255,.50);
    
    & > div {
        background-color:rgba(255,255,255,1);
        border:2px solid #eee;
        border-radius:10px;
        padding:40px;
        -webkit-box-shadow: 0 8px 6px -6px black;
        -moz-box-shadow: 0 8px 6px -6px black;
        box-shadow: 0 8px 6px -6px black;

        & > span {
            display:block;
            box-sizing: border-box;
            width: 80px;
            height: 80px;
            border-radius: 100%;
            border: 10px solid rgba(0, 0, 0, 0.1);
            border-top-color: #999;
            animation: spin .5s infinite linear;
        }

        @keyframes spin { 
            100% { 
                transform: rotate(360deg); 
            } 
        } 
    }
} 