@import "common-action-item-list.scss";
@import "loader-modal.scss";
@import "ckeditor.scss";
@import "bootstrap-colourselector.scss";
@import "save-modal.scss";

body {
    padding-top:70px; 
} 

div.title-row {
    h1 {
        margin-top:0 !important;
        border-bottom:1px solid #ddd;
        padding-bottom:15px;
        margin-bottom:30px;
        & small{
            text-transform:capitalize;
        }
    }
}

p.nhlpa-xl-text{
    font-size:16px;
    font-style:italic;
}
.nhlpa-subdued-text{
    color:#8d8d8d;
}

table.clickable-rows {
    & tbody tr{
        cursor:pointer;
    }
}