﻿.editor {
    & img:not([width]):not([height]) {
        max-width: 100%;
    }

    & figure.image {
        display: table;
        margin: 10px;

        & figure, & img {
            display: block;
            //width: 100%;
        }

        & figcaption {
            font-style: italic;
            display: table-caption;
            caption-side: bottom;
            padding: 5px;
            font-size: .8em;
            text-align: center;
        }
    }
}
