﻿.save-modal {

    & .modal-content {

        & .modal-header {
            display: none;
        }

        & .modal-footer {
            display: none;
        }

        & div.modal-body {
            & > div { // conainers
                display:flex;
                justify-content:center;
                &.ok {
                    display: none;
                }

                & > div {
                    flex:0 0 auto;
                    display:flex;
                    align-items:center;
                    & > *{
                        flex: 0 0 auto;

                        
                    }
                    & h3{
                        margin-bottom:0;
                    }
                }
            }
            & h3 {
                margin-top: 0;
            }

        }

        &.saved {
            & div.modal-body {
                & .loader{
                    display:none;
                }
                & .ok {
                    display:flex;
                }
            }
            & .modal-footer {
                display: block;
            }
        }
    }
}